<template lang="">
        <!-- footer-area -->
        <footer>
            <div class="footer-wrap">
                <div class="container">
                    <div class="row g-0">
                        <div class="col-3">
                            <div class="footer-widget">
                                <div class="footer-contact">
                                    <div class="footer-call">
                                        <div class="icon">
                                            <i class="flaticon-support"></i>
                                        </div>
                                        <div class="content">
                                            <span>Email us 24/7</span>
                                            <h6 class="title"><a href="mailto:support@local-wifi.com">support@local-wifi.com</a></h6>
                                        </div>
                                    </div>
                                    <h6 class="title">Contact info</h6>
                                    <p>(Email us, its faster)</p>
                                    <!-- <div class="footer-social">
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                        <a href="#"><i class="fab fa-instagram"></i></a>
                                        <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="footer-widget">
                                <div class="fw-title">
                                    <h4 class="title">Services</h4>
                                </div>
                                <ul class="fw-list">
                                    <li><a href="#/services?service=propertyNetworks">Hospitality Networks</a></li>
                                    <li><a href="#/services?service=captiveNetworks">Captive Networks</a></li>
                                    <li><a href="#/services?service=officeNetworks">Office Networks</a></li>
                                    <li><a href="#/services?service=publicNetworks">Public Networks</a></li>
                                    <li><a href="#/services?service=loyaltyPlus">Loyalty+</a></li>
                                </ul>
                            </div>
                        </div>
                        <!-- <div class="col-2">
                            <div class="footer-widget">
                                <div class="fw-title">
                                    <h4 class="title">About Us</h4>
                                </div>
                                <ul class="fw-list">
                                    <li><a href="about-us.html">Who are we?</a></li>
                                    <li><a href="contact.html">Our Mission</a></li>
                                    <li><a href="contact.html">Contact Us</a></li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="col-1">
                            <div class="footer-widget">
                                <div class="fw-title">
                                    <h4 class="title">Service Schedule</h4>
                                </div>
                                <ul class="fw-schedule-list">
                                    <li>Monday - Friday: <span>9:00 AM - 5:00 PM</span></li>
                                    <li>Saturday: <span>9:30 AM - 12 PM</span></li>
                                    <li>Sunday : <span class="close">Closed</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright-wrap">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <div class="footer-logo">
                                <a href="index.html"><img src="@/assets/logo.png" alt=""></a>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="copyright-text text-center text-md-end">
                                <p>Copyright - Local WiFi Ltd</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- footer-area-end -->
</template>