<template>
    <div class="col-lg-4 col-md-6 col-sm-9">
        <div class="pricing-item mb-30">
            <div class="pricing-thumb">
                <img :src="require(`@/assets/pricing/${image}`)" alt="">
                <h3 class="title">{{title}}</h3>
                <div class="net-speed">
                    <h5>{{users}} <span>Users*</span></h5>
                </div>
            </div>
            <div class="pricing-content">
                <ul class="pricing-list">
                    <li v-for="listItem in features.yes"><img src="img/icons/check-circle.png" alt=""> {{listItem}}</li>
                    <li v-for="listItem in features.no"><img src="img/icons/cross-circle.png" alt=""> {{listItem}}</li>
                </ul>
                <div class="price-wrap">
                    <span>Start From</span>
                    <h3 class="price">{{price}}<sub>/Month</sub></h3>
                </div>
                <div class="pricing-btn">
                    <a :href="googleFormsLink" class="btn btn-link">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'users', 'price', 'features', 'image'],
    data(){
        return {
            googleFormsLink : 'https://forms.gle/xtB5J5FoeTjoKK4h9'
        }
    }
}
</script>
<style lang="">
    
</style>