<template>
  <div>
    <!-- <Preloader /> -->
    <Scrolltop />
    <header>
      <PageHeader />
      <Navigation />
    </header>
    <main>
      <!-- Header -->
      <section class="banner-area banner-bg" style="background-image: url('img/slider/banner_bg.png');">
        <div class="container">
          <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-9">
              <div class="banner-content">
                <h2 class="title wow fadeInUp" data-wow-delay=".2s">Networking? We do it all.</h2>
                <p class="wow fadeInUp" data-wow-delay=".4s">Managed networks, guest networks, captive networks. We've got
                  you covered!</p>
                <div class="banner-price wow fadeInUp" data-wow-delay=".6s">
                  <h3 class="price">As little as £19.99<sub>/Month</sub></h3>
                </div>
                <a :href="googleFormsLink" class="btn wow fadeInUp" data-wow-delay=".8s">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Header -->

      <!-- What We Do -->
      <section class="inner-services-area pt-120">
          <div class="container mb-20">
              <div class="row align-items-center">
                  <div class="col-xl-7 col-lg-6 order-0 order-lg-2">
                      <div class="services-inner-img wow fadeInRight" data-wow-delay=".2s">
                          <img :src="require('@/assets/services.png')" alt="" style="border-radius: 10px;">
                      </div>
                  </div>
                  <div class="col-xl-5 col-lg-6">
                      <div class="section-title mb-30">
                          <h2 class="title">So, what exactly do we do?</h2>
                      </div>
                      <div class="services-inner-content">
                          <p>We will become your best friend in IT, an MSP that cares about you and your specific neeeds. In the modern world, everything is connected. It is important to be connected in a way that is safe, secure and easy to use.<br /><br />
                            We take pride in planning, installing and managing networks for anyone who needs one in the most cost effective way possible.<br /><br />
                            Networks are complex beasts, so why not offload that work on to us? We enjoy a challenge!
                          </p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="row justify-content-center">
                <!-- Hotels Networks -->
                  <div class="col-xl-3 col-lg-4 col-md-6">
                      <div class="services-item" :style="'background-image:url('+require('@/assets/service_hotel.png')+');'">
                          <div class="services-icon">
                              <i class="flaticon-sync"></i>
                          </div>
                          <div class="services-content">
                              <h3 class="title"><a href="#/services">Hotels</a></h3>
                              <p>5 rooms? 50 rooms? 500 rooms? We've got you covered! Even in that corner that everyone complains about.</p>
                              <a href="#/services" class="btn btn-link">Read more</a>
                          </div>
                      </div>
                  </div>
                  <!-- Office Networks -->
                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="services-item" :style="'background-image:url('+require('@/assets/service_office.png')+');'">
                          <div class="services-icon">
                              <i class="flaticon-businessman"></i>
                          </div>
                          <div class="services-content">
                              <h3 class="title"><a href="#/services">Offices</a></h3>
                              <p>From the most simple to offices with a few staff to multi department properties, we have just what you need.</p>
                              <a href="#/services" class="btn btn-link">Read more</a>
                          </div>
                      </div>
                  </div>
                  <!-- Venue Networks -->
                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="services-item" :style="'background-image:url('+require('@/assets/service_venue.png')+');'">
                          <div class="services-icon">
                              <i class="flaticon-wifi-router-1"></i>
                          </div>
                          <div class="services-content">
                              <h3 class="title"><a href="#/services">Venues</a></h3>
                              <p>Got a venue for special occasions? Or a confrence space you need connected? Reach out to get connected!</p>
                              <a href="#/services" class="btn btn-link">Read more</a>
                          </div>
                      </div>
                  </div>
                  <!-- Captive Networks -->
                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="services-item" :style="'background-image:url('+require('@/assets/service_captive.png')+');'">
                          <div class="services-icon">
                              <i class="flaticon-megaphone"></i>
                          </div>
                          <div class="services-content">
                              <h3 class="title"><a href="#/services">Captive Networking</a></h3>
                              <p>Got guests? Why not give them a fully branded end to end experience and find how exactly how your network is being used.</p>
                              <a href="#/services" class="btn btn-link">Read more</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <!-- What We Do End -->

      <!-- How We Work -->
      <section class="about-area pt-100">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-lg-6">
                      <div class="about-img-wrap">
                          <img :src="require('@/assets/hdww1.png')" alt="">
                          <img :src="require('@/assets/hdww2.png')" class="main-img" alt="">
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="section-title mb-30">
                          <h2 class="title">How do we work?</h2>
                      </div>
                      <div class="about-content">
                          <p>Our goal is to get you connected in the most streamlined, time efficient way possible. We start by getting some basic information from you about your site requirements and contact local ISPs to find a broadband package that suits your needs.</p>
                          <br /><p>Once we have verified your site can have the bandwidth necessary to meet your specification, then we get to work with a site visit. From there, we determine what is necessary at the property and give you a quote. If you are happy, we commence work as soon as it suits!</p>
                          <ul class="about-list">
                              <li>Expert technical team</li>
                              <li>Granular quotes</li>
                              <li>Zero hidden costs</li>
                              <li>Simple payment options</li>
                          </ul>
                          <a href="https://forms.gle/xtB5J5FoeTjoKK4h9" class="btn transparent-btn">Get in touch!</a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <!-- How We Work End -->

      <!-- Bespoke Networks -->
      <section class="pricing-two-area pt-100" style="margin-top:0px !important;">
            <div class="container">
              <div class="col-lg-12">
                    <div class="section-title mb-40">
                        <h2 class="title">Bespoke Network Costs</h2><br />
                        <p>For larger scale deployments, you will need a network built from the ground up. Our pricing is upfront and transparent with no hidden costs. Take a look!</p>
                    </div>
                    </div>
              <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6 col-sm-9">
                      <div class="pricing-two-item" data-background="img/images/pricing_item_bg01.jpg">
                          <div class="pricing-two-content">
                              <h3 class="title">Router Management</h3>
                              <p>The core of your network, used to manage all traffic and talk to the outside world.</p>
                              <h3 class="price"><sup>£</sup>30<span>Per Unit <br> /Month</span></h3>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-9">
                      <div class="pricing-two-item" data-background="img/images/pricing_item_bg02.jpg">
                          <div class="pricing-two-content">
                              <h3 class="title">Switch Management</h3>
                              <p>Used to distribute your network throughout your site and power IoT devices</p>
                              <h3 class="price"><sup>£</sup>15<span>Per Unit <br> /Month</span></h3>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-9">
                      <div class="pricing-two-item" data-background="img/images/pricing_item_bg03.jpg">
                          <div class="pricing-two-content">
                              <h3 class="title">Access Point Management</h3>
                              <p>Allowing guests & staff to connect to your network securley and wirelessly from anywhere.</p>
                              <h3 class="price"><sup>£</sup>10<span>Per Unit <br> /Month</span></h3>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
      </section>
      <!-- Bespoke Networks End -->

      <!-- Vendors  -->
      <div class="brand-area pt-10">
          <div class="container">
              <div class="row brand-active">
                  <div class="col" v-for="(brand, index) in brands" :key="index">
                      <div class="brand-item">
                          <a href="#"><img :src="require(`@/assets/brand_${brand}.png`)" alt=""></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Vendors End -->

      <!-- Cafe -->
      <section class="pricing-area pt-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-8">
              <div class="section-title text-center mb-50">
                <h2 class="title">Cafe & Restaurant Plans</h2>
                <p>Leverage your existing broadband connection and provide a bespoke experience for your guests, and a more secure environment for your staff and critical infrastructure.</p>
              </div>
            </div>
          </div>
          <div class="pricing-plan-wrap">
            <div class="row justify-content-center">
              <PricePreview v-for="pricePreview in pricePreviews" :title="pricePreview.title" :users="pricePreview.users"
                :price="pricePreview.price" :features="pricePreview.features" :image="pricePreview.image" />
            </div>
          </div>
        </div>
      </section>
      <!-- Cafe End -->

      <!-- Fee -->
      <div class="container pt-10">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-10">
            <div class="pricing-cta-content text-center">
              <p>Due to the specialist nature of the hardware we use, a one time installation fee is required. This is calculated on a user by user basis.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Fee -->

      <!-- Price Challenge  -->
      <section class="pricing-cta-area pb-50">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-10">
              <div class="pricing-cta-content text-center">
                <h4 class="title">Don't like these prices? We might be able to help.</h4>
                <p>We do everything we can to make our products and services affordable. Not only for you as a business owner to gain further insight into your audience, but for the users themselves, to ensure their safety and give them an experience that will keep them coming back.</p>
                <a :href="googleFormsLink" target="_blank" class="btn">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Price Challenge End  -->

      <!-- Call to Action -->
      <section class="cta-area cta-bg jarallax" style="background-image: url('img/bg/cta_bg.png');">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-9 col-lg-10">
              <div class="cta-content text-center">
                <div class="section-title white-title mb-35">
                  <h2 class="title">Need a network? Big or small, its our game. Get in touch!</h2>
                </div>
                <div class="cta-btn-wrap">
                  <a :href="googleFormsLink" class="btn">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Call to Action End  -->
      <Footer />
    </main>
  </div>
</template>

<script>
import Preloader from '../components/Preloader.vue'
import Scrolltop from '../components/Scrolltop.vue'
import PageHeader from '../components/Header.vue'
import Navigation from '../components/Navigation.vue'
import PricePreview from '../components/PricePreview.vue'
import Footer from '../components/Footer.vue'
export default {
  components: {
    Preloader,
    Scrolltop,
    PageHeader,
    Navigation,
    PricePreview,
    Footer
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    $('.brand-active').slick({
	dots: false,
	infinite: true,
	speed: 1000,
	autoplay: true,
	arrows: false,
	slidesToShow: 5,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
				infinite: true,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: false,
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				arrows: false,
			}
		},
	]
});
  },
  data() {
    return {
      brands:[
        'mikrotik',
        'tplink',
        'ion',
        'nomadix',
        'cisco',
        'bt',
        'fibrus',
        'opengear',
        'aruba',
        'watchguard'
      ],
      googleFormsLink : 'https://forms.gle/xtB5J5FoeTjoKK4h9',
      pricePreviews: [
        {
          title: "Small Cafe / Restaurant",
          users: "0-30",
          price: "£19.99",
          features: {
            yes: ['Network Segregation', 'Custom Network Name'],
            no: ['User Analytics','Custom Captive Portal']
          },
          image: 'price_thumb_one.png'
        },
        {
          title: "Medium Cafe / Restaurant",
          users: "0-50",
          price: "£29.99",
          features: {
            yes: ['Network Segregation', 'Custom Captive Portal','Custom Network Name', 'User Analytics',]
          },
          image: 'price_thumb_two.png'
        },
        {
          title: "Large Cafe / Restaurant",
          users: "50-100",
          price: "£39.99",
          features: {
            yes: ['Dedicated Account Manager', 'Network Segregation', 'Custom Captive Portal','Custom Network Name', 'User Analytics']
          },
          image: 'price_thumb_three.png'
        },
      ]
    }
  }
}
</script>

<style>
.services-content p{
  cursor: default !important;
}

.pricing-two-item{
  padding:30px 40px 20px;
}

</style>
