<template>
    <div id="sticky-header" class="menu-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div>
                    <div class="menu-wrap">
                        <nav class="menu-nav">
                            <div class="logo">
                                <a href="#/"><img src="@/assets/logo.png" alt=""></a>
                            </div>
                            <div class="navbar-wrap main-menu d-none d-lg-flex" ref="mainMenu">
                                <ul class="navigation">
                                    <li :class="isActive('home')"><a href="#/">Home</a></li>
                                    <!-- <li><a href="about-us.html">About Us</a></li> -->
                                    <li :class="isActive('services', true)"><a href="#/services">Services</a>
                                        <ul class="sub-menu">
                                            <li><a href="#/services?service=captiveNetworks">Captive Networks</a></li>
                                            <li><a href="#/services?service=propertyNetworks">Hospitality Networks</a></li>
                                            <li><a href="#/services?service=officeNetworks">Office Networks</a></li>
                                            <li><a href="#/services?service=publicNetworks">Public Networks</a></li>
                                            <li><a href="#/services?service=analytics">Analytics</a></li>
                                            <li><a href="#/services?service=loyaltyPlus">Loyalty+</a></li>
                                        </ul>
                                    </li>
                                    <!-- <li><a href="contact.html">Contact us</a></li> -->
                                </ul>
                            </div>
                            <div class="header-action d-none d-md-block">
                                <ul>
                                    <li class="header-btn"><a :href="googleFormsLink" class="btn transparent-btn">Need a
                                            network?</a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <!-- Mobile Menu  -->
                    <div class="mobile-menu">
                        <nav class="menu-box">
                            <div class="close-btn"><i class="fal fa-times"></i></div>
                            <div class="nav-logo"><a href="#/"><img src="@/assets/logo.png" alt="" title=""></a>
                            </div>
                            <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
                            <div class="menu-outer" ref="menuOuter">
                            </div>
                            <!-- <div class="social-links">
                                <ul class="clearfix">
                                    <li><a href="#"><span class="fab fa-facebook-f"></span></a></li>
                                    <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                                    <li><a href="#"><span class="fab fa-pinterest-p"></span></a></li>
                                    <li><a href="#"><span class="fab fa-instagram"></span></a></li>
                                    <li><a href="#"><span class="fab fa-youtube"></span></a></li>
                                </ul>
                            </div> -->
                        </nav>
                    </div>
                    <div class="menu-backdrop"></div>
                    <!-- End Mobile Menu -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            googleFormsLink : 'https://forms.gle/xtB5J5FoeTjoKK4h9'
        }
    },
    methods : {
        isActive(need, hasChildren = false){
            let menuItemClass = 'menu-item'
            if(hasChildren){
                menuItemClass = 'menu-item-has-children'
            }
            if(this.$route.name === need){
                return 'active '+menuItemClass;
            }
            return menuItemClass;
        }
    },
    mounted() {

        let body = document.getElementsByTagName('body')[0];
        if(body.classList.contains('mobile-menu-visible')){
            body.classList.remove('mobile-menu-visible');
        }

        /*=============================================
            =    		Mobile Menu			      =
        =============================================*/

        //SubMenu Dropdown Toggle
        if ($('.menu-area li.menu-item-has-children ul').length) {
            $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

        }
        //Mobile Nav Hide Show
        if ($('.mobile-menu').length) {
            
            var mobileMenuContent = $('.menu-area .main-menu').html();
            this.$refs.menuOuter.innerHTML = mobileMenuContent;

            //Dropdown Button
            $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
                $(this).toggleClass('open');
                $(this).prev('ul').slideToggle(500);
            });
            //Menu Toggle Btn
            $('.mobile-nav-toggler').on('click', function () {
                $('body').addClass('mobile-menu-visible');
            });

            //Menu Toggle Btn
            $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
                $('body').removeClass('mobile-menu-visible');
            });
        }
    }
}
</script>