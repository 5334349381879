<template>
    <div>
    <div class="header-top-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-md-7 col-sm-7">
                        <div class="header-top-left">
                            <ul>
                                <li class="d-none d-xl-flex"><i class="flaticon-location"></i> Belfast, Northern Ireland</li>
                                <li class="d-none d-lg-flex"><i class="flaticon-email"></i> info@local-wifi.com</li>
                                <li><i class="flaticon-clock"></i> Support Hours : 9 AM - 10 PM</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-5 col-sm-5">
                        <div class="header-top-right">
                            <ul>
                                <li class="header-user-info">
                                    <i class="flaticon-wifi-router-1"></i>
                                    <a href="https://admin.local-wifi.com/">Login</a>
                                </li>
                                <!-- <li class="header-social">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="header-top-fixed"></div>
    </div>
</template>

<script>

export default{
    data(){
        return {
            googleFormsLink : 'https://forms.gle/xtB5J5FoeTjoKK4h9'
        }
    }
}

</script>