<template lang="">
    <!-- breadcrumb-area -->
    <section :class="'breadcrumb-area breadcrumb-bg ' + bgClass">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-8">
                    <div class="breadcrumb-content">
                        <h3 class="title">{{title}}</h3>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li v-for="(crumb, index) in crumbs" class="breadcrumb-item" :key="index"><a :href="crumb.location">{{crumb.title}}</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- breadcrumb-area-end -->
</template>
<script>
export default {
    props:['title', 'crumbs', 'bgClass'],
}
</script>
<style>
    .breadcrumb-item:last-child a{
        color:black;
    }
</style>