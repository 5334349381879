<template>
    <div>
        <!-- <Preloader /> -->
        <Scrolltop />
        <header>
            <PageHeader />
            <Navigation />
        </header>
        <main>
            <Breadcrumb title="Our Services"
                :crumbs="[{ title: 'Home', location: '#/' }, { title: 'Services', location: '#/services' }]"
                bgClass="service-breadcrumb-bg" />
            <!-- services-details -->
            <div class="services-details-area pt-120 pb-60">
                <div class="container">
                    <div class="row services-details-wrap">
                        <div class="col-4">
                            <aside class="services-sidebar">
                                <div class="widget services-widget">
                                    <ul class="services-sidebar-list">
                                        <li v-for="(serviceItem, serviceKey) in serviceItems"
                                            :class="serviceItem.serviceDetailsKey === activeServiceItemKey ? 'active' : ''">
                                            <a class="serviceMenuItem" @click="setActiveServiceItem(serviceItem)"><span
                                                    class="count">0{{ serviceKey + 1 }}.</span>{{ serviceItem.title }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div class="col-8">
                            <div class="services-details-content">
                                <div style="display: flex; justify-content: center; align-items: center;" v-if="activeServiceItemKey === 'loyaltyPlus'">
                                    <img src="@/assets/LoyaltyPlus.png" style="height:100px; margin-bottom: 50px;">
                                </div>
                                <template v-for="activeServiceDetail in activeServiceDetails.literature">
                                    <h2 class="title">{{ activeServiceDetail.title }}</h2>
                                    <p v-for="contentLine in activeServiceDetail.content">{{ contentLine }}</p>
                                </template>
                                <div class="services-faq-wrap">
                                    <div class="faq-wrapper" v-if="activeServiceDetails.faq">
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item" v-for="(faq, faqKey) in activeServiceDetails.faq"
                                                :key="faqKey">
                                                <h2 class="accordion-header" :id="'faqKey' + faqKey">
                                                    <button class="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" :data-bs-target="'#faqKeyCollapse' + faqKey"
                                                        aria-expanded="true" :aria-controls="'faqKeyCollapse' + faqKey">
                                                        {{ faq.q }}
                                                    </button>
                                                </h2>
                                                <div :id="'faqKeyCollapse' + faqKey" class="accordion-collapse collapse"
                                                    :aria-labelledby="'faqKey' + faqKey" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {{ faq.a }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="services-details-bottom">
                                    <a href="contact.html" class="btn">Contact Us</a>
                                    <div class="footer-call">
                                        <div class="icon">
                                            <i class="flaticon-support"></i>
                                        </div>
                                        <div class="content">
                                            <span>Why not email us?</span>
                                            <h6 class="title"><a
                                                    href="mailto:support@local-wifi.com">support@local-wifi.com</a></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- services-details-end -->
            <Footer />
        </main>
    </div>
</template>
  
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Scrolltop from '../components/Scrolltop.vue'
import PageHeader from '../components/Header.vue'
import Navigation from '../components/Navigation.vue'
import PricePreview from '../components/PricePreview.vue'
import Footer from '../components/Footer.vue'
import { setDevtoolsHook } from 'vue'
export default {
    components: {
        Breadcrumb,
        Scrolltop,
        PageHeader,
        Navigation,
        PricePreview,
        Footer
    },
    watch : {
        '$route.params.search' : {
            handler : function (){
                if(this.$route.query.service){
                    let activeServiceDetailKey = this.$route.query.service;
                    this.activeServiceItemKey = activeServiceDetailKey;
                    this.activeServiceDetails = this.serviceDetails[activeServiceDetailKey];
                }
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        let activeServiceDetailKey = 'captiveNetworks';
        if(this.$route.query.service){
            activeServiceDetailKey = this.$route.query.service;
        }
        this.activeServiceItemKey = activeServiceDetailKey;
        this.activeServiceDetails = this.serviceDetails[activeServiceDetailKey];
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    },
    methods: {
        setActiveServiceItem(serviceItem) {
            this.activeServiceItemKey = serviceItem.serviceDetailsKey;
            this.activeServiceDetails = this.serviceDetails[serviceItem.serviceDetailsKey];
        }
    },
    data() {
        return {
            activeServiceItemKey: 0,
            activeServiceDetails: {},
            serviceItems: [
                { title: 'Captive Portals', serviceDetailsKey:'captiveNetworks'},
                { title: 'Hospitality Networks', serviceDetailsKey:'propertyNetworks'},
                { title: 'Office Networks', serviceDetailsKey:'officeNetworks'},
                { title: 'Public Networks', serviceDetailsKey:'publicNetworks'},
                { title: 'Analytics', serviceDetailsKey:'analytics'},
                { title: 'Loyalty+', serviceDetailsKey:'loyaltyPlus'},
            ],
            serviceDetails: {
                captiveNetworks: {
                    literature: [
                        {
                            title: 'What Are Captive Networks?',
                            content: [
                                'Captive Portals are a common mechanism used by public Wi-Fi networks, hotels, airports, coffee shops, and other establishments to control and manage user access to their wireless networks. The primary purpose of captive portals is to enforce certain conditions or terms of use before allowing users to access the internet.',
                                ' When you connect to a public Wi-Fi network that utilizes a captive portal, such as "Free Wi-Fi" in a coffee shop, your device will automatically detect the network, and you\'ll be able to connect to it. However, upon connecting, any attempt to access a website or online service will redirect you to a specific login page or terms of service agreement.'
                            ]
                        },
                        {
                            title: 'Do I need one?',
                            content: [
                                'Captive Networks present a win-win scenario for businesses and users. Businesses can benefit from enhanced customer engagement, increased revenue, and improved data-driven decision-making, while users enjoy free Wi-Fi access in a secure and controlled environment. As the demand for internet connectivity continues to grow, captive networks remain a valuable tool for businesses to meet customer expectations and optimize their digital strategies.',
                                'Captive networks allow businesses and organizations to control who can access their Wi-Fi. By implementing a captive portal, they can enforce terms of use, collect user data, and protect their network from unauthorized or malicious users. This control helps ensure a safe and secure browsing environment for all users connected to the network.',
                                'Offering free Wi-Fi through a captive network has become a customer expectation for businesses such as coffee shops, hotels, restaurants, and airports. Providing this amenity attracts customers, enhances their experience, and encourages them to spend more time on the premises, potentially leading to increased patronage and loyalty.',
                                'Businesses can customize captive portals to reflect their brand image, offering a cohesive and personalized experience to users. This branding opportunity helps reinforce brand recognition and creates a more professional and engaging environment for users.',
                                'Captive networks enable businesses to gather valuable data on their customers. When users log in through the captive portal, businesses can obtain insights into user demographics, browsing behavior, and preferences. This data can be leveraged for targeted marketing campaigns, improving services, and understanding customer needs.'
                            ]
                        }
                    ],
                    faq: [
                        {
                            q: 'How much does it cost?',
                            a: 'Contact us now to find out exactly how much your deployment will cost. You can get started from as little as £15.99/Pm'
                        },
                        {
                            q: 'Can I customize what my users see?',
                            a: 'Yes! As part of your initial setup, we will help make your portal look and feel like an extension of your existing image!'
                        },
                        {
                            q: 'Can I offer rewards to loyal customers?',
                            a: 'Check out out Loyalty+ application! It does just that.'
                        },
                    ]
                },
                propertyNetworks: {
                    literature: [
                        {
                            title: 'Hospitality Networks',
                            content: [
                                'Hospitality networks refer to the Wi-Fi networks provided by establishments in the hospitality industry, such as hotels, resorts, appartments, assisted living and other lodging facilities, to offer internet access to their guests. These networks are designed to cater to the specific needs of travelers and patrons, providing reliable and secure connectivity throughout their stay',
                                'Overall, hospitality networks are tailored to meet the diverse needs of guests and visitors, providing a seamless and enjoyable internet experience during their stay. As technology continues to evolve, the hospitality industry continues to adapt and enhance its networks to offer an ever-improving level of service to its clientele.',
                                'The most basic type of hospitality network is the guest Wi-Fi network. This network is accessible to all guests staying at the establishment and usually requires a simple password or agreement to the terms of use through a captive portal. Guest Wi-Fi networks typically provide internet access for general browsing, email, and social media.'
                            ]
                        },
                        {
                            title: 'What about my staff?',
                            content: [
                                'Hotels and resorts often have a separate Wi-Fi network for their staff members. This network allows employees to access essential business applications, communicate with each other, and perform work-related tasks efficiently without affecting the guest Wi-Fis performance.',
                                'Many hospitality establishments offer Wi-Fi access in public areas such as lobbies, restaurants, pool areas, and lounges. This network enables guests to stay connected even when they are not in their rooms.'
                            ]
                        },
                    ],
                    faq: [
                    ]
                },
                officeNetworks: {
                    literature: [
                        {
                            title: 'Office Networks',
                            content: [
                                'Office networks are essential for facilitating communication, collaboration, and data sharing within a workplace. Depending on the size and requirements of the organization, there are various types of office networks.',
                                'A Local Area Network (LAN) is the most common type of office network. It connects computers, devices, and resources within a limited geographic area, such as a single office building or campus. LANs provide high-speed and reliable connectivity, enabling employees to share files, printers, and other resources efficiently.',
                                'The choice of network architecture depends on factors such as the organization\'s size, budget, geographic distribution, security requirements, and the need for remote access and collaboration capabilities.'
                            ]
                        },
                    ],
                    faq: [
                        {
                            q:'What size of office do I need?',
                            a:'We accommodate anyone! If you are the only person in your office, or a team of as many as you can imagine. We will build a bespoke solution that is right for you.'
                        },
                        {
                            q:'How much will it cost?',
                            a:'All office deployments are unique so we cant give you a catch all price. Get in touch and we will calculate a quote specific to your needs based on office size, required SSIDs, wired connectivity and network monitoring needs'
                        }
                    ]
                },
                publicNetworks: {
                    literature: [
                        {
                            title: 'Public Networks',
                            content: [
                                'Providing free public Wi-Fi has numerous benefits for a city, its residents, and visitors.',
                                'Free public Wi-Fi promotes digital inclusion by bridging the digital divide. Not everyone can afford internet access at home, and public Wi-Fi offers an opportunity for all residents, regardless of their income, to stay connected and access online resources.',
                                'Tourists and visitors appreciate having free internet access to stay connected with friends and family, share experiences, and access travel information. It improves their overall experience and encourages them to explore more of what the city has to offer.',
                                'Free public Wi-Fi attracts tourists and visitors, enhancing the overall appeal of the city. When people have access to the internet, they can find information about local businesses, attractions, and events, leading to increased foot traffic and economic activity in shops, restaurants, and other establishments.',
                                'Public Wi-Fi enables residents and visitors to access maps, transportation schedules, and real-time updates on public services. This helps people navigate the city more efficiently and stay informed about important news and events.',
                                'In times of emergencies or natural disasters, free public Wi-Fi can become a vital communication tool. People can access emergency alerts, get in touch with loved ones, and seek help if needed.',
                                'Public Wi-Fi networks are a crucial element of smart city initiatives. They can be integrated with other technologies to enhance public services, such as smart transportation systems, waste management, and environmental monitoring.',
                                'Encouraging the use of free public Wi-Fi reduces the need for personal mobile data plans, which can lead to a reduction in carbon emissions associated with data usage and energy consumption.',
                                'In conclusion, free public Wi-Fi not only addresses the digital divide but also plays a significant role in boosting economic growth, improving tourism, enhancing education, and promoting community engagement. Implementing free public Wi-Fi is a proactive step towards building a more inclusive, connected, and technologically advanced city.'
                            ]
                        },
                    ],
                    faq: [
                        {
                            q:'Who is are these networks best suited for?',
                            a:'These networks are specifically designed for free, open, public use and are often ran by local government bodies or charities.'
                        }
                    ]
                },
                analytics: {
                    literature: [
                        {
                            title: 'Analytics',
                            content: [
                                'Network analytics provide essential insights and data about the performance, security, and efficiency of your network.',
                                'By analyzing user behavior and application usage, organizations can enhance the user experience. Understanding which applications consume the most resources and cause delays allows them to prioritize critical applications and improve overall satisfaction.',
                                'Network analytics can offer valuable business insights. For example, understanding peak usage times or popular services can inform marketing strategies and help organizations make data-driven decisions.',
                                'Understanding customer behavior through analytics enables you engage with your customers more effectively. The data can be used to personalize promotions, offer loyalty rewards, and send relevant notifications, which can increase customer loyalty and retention.',
                                'With network analytics, you can evaluate the effectiveness of their marketing campaigns, track how many customers accessed the Wi-Fi during specific promotions or events, providing valuable data to measure the ROI of marketing efforts.'
                            ]
                        },
                    ],
                    faq: [
                    ]
                },
                loyaltyPlus : {
                    literature : [
                        {
                            title:'What is Loyalty+',
                            content : [
                                'Loyalty+ is our flagship product for Cafes that offer WiFi to their customers.',
                                'Loyalty+ ties in with our existing admin suite allowing you to enhance your customer experience even further! Define rewards for your loyal customers, be it a coffee, or a meal on the house!',
                                'By utilizing the existing user connection data captured when they connect to your network via your captive portal, we can determine how many times a customer has visited you, based on this, you can define the rewards they get over time.'
                            ]
                        },
                        {
                            title:'How much does Loyalty+ cost?',
                            content : [
                                'As long as you are an existing Local WiFi customer, with a Captive Portal and Analytics enabled, Loyalty+ is a simple, flat £5.99 per month per site',
                            ]
                        }
                    ]
                }
            }
        }
    }
}
</script>
<style>
.serviceMenuItem {
    cursor: pointer;
}
</style>
  